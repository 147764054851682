<script>
import ModalCaptureImage from '@/components/customization/modalCaptureImage.vue'
import { mapActions } from "vuex";
import Spinner from '@/components/Spinner.vue';
import Question from "@/components/Modales/Question.vue";

export default {
	components: { ModalCaptureImage, Spinner, Question },
	
	data() {
		return {
			id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
			logos_dimensions: {
				logo_web: { name: 'logo_web', height: '50', width: '200' },
				logo_app: { name: 'logo_app', height: '50', width: '200' },
				logo_isotipo: { name: 'logo_isotipo', height: '40', width: '50' }
			},
			colores: ['0072F7', 'F26F28', '6C6CFF', '4BBE61', 'E64141', '52CEC1', 'F6C700', 'EC206B', '30A2B7'],
			image: { name: '', height: '0', width: '0' },
			form: {
				color: '',
				images: {
					logo_web: '',
					logo_app: '',
					logo_isotipo: ''
				}
			},
			show_spinner: false,
			open_question_modal: false,
			question_modal_msg: "Los logos que no hayas cargado se guardarán como los predeterminados de Linkiwork. ¿Estás de acuerdo en continuar?",
			savedInterfaz: {},
		}
	},

	async created() {
		this.savedInterfaz = await this.getInterfaz();
		if (this.savedInterfaz) {
			this.form.color = this.savedInterfaz.color_primario;
			this.form.images.logo_web = this.savedInterfaz.logo_empresa;
			this.form.images.logo_app = this.savedInterfaz.logo_app_empresa;
			this.form.images.logo_isotipo = this.savedInterfaz.isotipo_empresa;
		}
	},

	methods: {
		...mapActions("customizationModule", ["getInterfaz", "editInterfaz"]),

		setCropperLogoWeb() {
			this.image = this.logos_dimensions.logo_web;
		},
		setCropperLogoApp() {
			this.image = this.logos_dimensions.logo_app;
		},
		setCropperLogoIsotipo() {
			this.image = this.logos_dimensions.logo_isotipo;
		},
		captureImage(img, name) {
			this.form.images[`${name}`] = img
		},
		captureColor(color) {
			this.form.color = color;
		},

		async save() {
			this.open_question_modal = false;
			this.show_spinner = true;
			// ver por qué no se abre bien la imagen luego de seleccionarla en el filesystem desde "Logotipo app" 
			// después de croppear y seleccionar "Logotipo web"
			const payload = {
				color_primario: this.form.color
			};

			if (this.form.images.logo_web.includes("data:image/")) {
				payload.logo_web         = this.form.images.logo_web?.split(/:|;|,/)[3];
				payload.formato_logo_web = this.form.images.logo_web?.split(/:|;|,/)[1];
			} else if (this.form.images.logo_web === "") {
				payload.logo_web         = "";
				payload.formato_logo_web = "";
			}
			if (this.form.images.logo_app.includes("data:image/")) {
				payload.logo_app         = this.form.images.logo_app?.split(/:|;|,/)[3];
				payload.formato_logo_app = this.form.images.logo_app?.split(/:|;|,/)[1];
			} else if (this.form.images.logo_app === "") {
				payload.logo_app         = "";
				payload.formato_logo_app = "";
			}
			if (this.form.images.logo_isotipo.includes("data:image/")) {
				payload.logo_isotipo         = this.form.images.logo_isotipo?.split(/:|;|,/)[3];
				payload.formato_logo_isotipo = this.form.images.logo_isotipo?.split(/:|;|,/)[1];
			} else if (this.form.images.logo_isotipo === "") {
				payload.logo_isotipo         = "";
				payload.formato_logo_isotipo = "";
			}

			const res = await this.editInterfaz(payload);
			if (!res) {
				this.toastEditError();
			} else {
				this.toastEditSuccess();
			}

			this.show_spinner = false;
		},

		onSubmit() {
			if (!this.form.color) {
				this.toastNoColorWarning();
				return;
			}

			let areAllLogosSet = 1;
			areAllLogosSet &= !!this.form.images.logo_web;
			areAllLogosSet &= !!this.form.images.logo_app;
			areAllLogosSet &= !!this.form.images.logo_isotipo;
			if (!areAllLogosSet) {
				this.open_question_modal = true;
			} else {
				this.save();
			}
		},

		deleteLogoWeb() {
			this.form.images.logo_web = "";
		},
		deleteLogoApp() {
			this.form.images.logo_app = "";
		},
		deleteLogoIsotipo() {
			this.form.images.logo_isotipo = "";
		},

		closeQuestion() {
			this.open_question_modal = false;
		},

		toastNoColorWarning() {
			this.$toast.open({
				message: "Por favor selecciona un color para continuar.",
				type: "warning",
				duration: 6000,
				position: "top-right",      
			});        
		},
		toastEditError() {
			this.$toast.open({
				message: "Error en el envío de información. Por favor recarga la página e intenta nuevamente.",
				type: "error",
				duration: 5000,
				position: "top-right",
			});
		},
		toastEditSuccess() {
			this.$toast.open({
				message: "Se ha editado la interfaz exitosamente.",
				type: "success",
				duration: 5000,
				position: "top-right"
			});
		},
	},

}
</script>
<template>
	<form @submit.prevent="onSubmit">
		<h5 class="mt-3 mt-lg-0 font-main text-secondary">
			<i class="fa-solid fa-fill-drip color-main"></i>
			Logotipos y colores
		</h5>
		<h6 class="font-main text-secondary border-bottom border-color mb-3 pb-3 text-opacity-50">Selecciona el color principal para tu Linkiwork y añade el logotipo e isotipo de tu empresa.</h6>
		<div class="card border-0 card-shadow-light mb-3">
			<div class="card-body">
				<h6 class="text-secondary font-main text-opacity-75">
					Selecciona un color principal
				</h6>
				<div class="d-flex flex-wrap gap-3">
					<a 
						v-for="(item, index) in colores" :key="index"
						@click="captureColor(item)"
						href="javascript:"
						class="square-color"
						:style="`background-color: #${item}`"
					>
						<span v-if="form.color === item">
							<i class="fa-solid fa-check square-color-icon"></i>
						</span>
					</a>
				</div>
			</div>
		</div>
		<div class="d-flex flex-wrap gap-3">
			<div class="card border-0 card-shadow-light d-flex align-items-center flex-fill">
				<div class="card-body">
					<h6 class="text-secondary font-main text-opacity-75">
						<i class="fa-solid fa-laptop"></i>
						Logotipo web
					</h6>
					<div 
						class="capture-image"
						:style="`width: ${parseInt(logos_dimensions.logo_web.width) + 42}px; height: ${parseInt(logos_dimensions.logo_web.height) + 42}px;`"
					>
						<template v-if="form.images.logo_web">
							<button @click="deleteLogoWeb()" class="upload-logo-remove">
								<font-awesome-icon icon="times"/>
							</button>
						</template>
						<a 
							@click="setCropperLogoWeb()"
							href="javascript:"
							class="capture-image-link"
							data-bs-toggle="modal"
							data-bs-target="#modal-capture-image"
							:style="`width: ${parseInt(logos_dimensions.logo_web.width) + 22}px; height: ${parseInt(logos_dimensions.logo_web.height) + 22}px;`"
						>
							<template v-if="!form.images.logo_web">
								<img class="sidebar-logo-complete" src="@/assets/img/twu_logo.svg" style="opacity:0.3;position:absolute">
								<span>
									<i class="h2 fa-regular capture-img-icon fa-image mb-0"></i>
								</span>
							</template>
							<template v-else>
								<img :src="form.images.logo_web">
							</template>
						</a>
					</div>
					<div class="border border-gray-light rounded p-1 mt-1 mb-0 text-secondary text-opacity-75 text-center">
						<small>{{ logos_dimensions.logo_web.width }} x {{ logos_dimensions.logo_web.height }} px</small>
					</div>
				</div>
			</div>

			<div class="card border-0 card-shadow-light d-flex align-items-center flex-fill">
				<div class="card-body">
					<h6 class="text-secondary font-main text-opacity-75">
						<i class="fa-solid fa-mobile-screen-button"></i>
						Logotipo app
					</h6>
					<div 
						class="capture-image"
						:style="`width: ${parseInt(logos_dimensions.logo_app.width) + 42}px; height: ${parseInt(logos_dimensions.logo_app.height) + 42}px;`"
					>
						<template v-if="form.images.logo_app">
							<button @click="deleteLogoApp()" class="upload-logo-remove">
								<font-awesome-icon icon="times"/>
							</button>
						</template>

						<a 
							@click="setCropperLogoApp()"
							href="javascript:"
							class="capture-image-link"
							data-bs-toggle="modal"
							data-bs-target="#modal-capture-image"
							:style="`width: ${parseInt(logos_dimensions.logo_app.width) + 22}px; height: ${parseInt(logos_dimensions.logo_app.height) + 22}px;`"
						>
							<template v-if="!form.images.logo_app">
								<img class="sidebar-logo-complete" src="@/assets/img/twu_logo.svg" style="opacity:0.3;position:absolute">
								<span>
									<i class="h2 fa-regular capture-img-icon fa-image mb-0"></i>
								</span>
							</template>
							<template v-else>
								<img :src="form.images.logo_app">
							</template>

						</a>
					</div>
					<div class="border border-gray-light rounded p-1 mt-1 mb-0 text-secondary text-opacity-75 text-center">
						<small>{{ logos_dimensions.logo_app.width }} x {{ logos_dimensions.logo_app.height }} px</small>
					</div>
				</div>
			</div>

			<div class="card border-0 card-shadow-light d-flex align-items-center flex-fill">
				<div class="card-body">
					<h6 class="text-secondary font-main text-opacity-75">
						<i class="fa-regular fa-square"></i>
						Isotipo
					</h6>
					<div 
						class="capture-image"
						:style="`width: ${parseInt(logos_dimensions.logo_isotipo.width) + 42}px; height: ${parseInt(logos_dimensions.logo_isotipo.height) + 42}px;`"
					>
						<template v-if="form.images.logo_isotipo">
							<button @click="deleteLogoIsotipo()" class="upload-logo-remove">
								<font-awesome-icon icon="times"/>
							</button>
						</template>

						<a 
							@click="setCropperLogoIsotipo()"
							href="javascript:"
							class="capture-image-link"
							data-bs-toggle="modal"
							data-bs-target="#modal-capture-image"
							:style="`width: ${parseInt(logos_dimensions.logo_isotipo.width) + 22}px; height: ${parseInt(logos_dimensions.logo_isotipo.height) + 22}px;`"
						>
							<template v-if="!form.images.logo_isotipo">									
								<img class="sidebar-logo-complete" src="@/assets/img/twu_letra.svg" style="opacity:0.3;position:absolute">
								<span>
									<i class="h2 fa-regular capture-img-icon fa-image mb-0"></i>
								</span>
							</template>
							<template v-else>
								<img :src="form.images.logo_isotipo">
							</template>

						</a>
					</div>
					<div class="border border-gray-light rounded p-1 mt-1 mb-0 text-secondary text-opacity-75 text-center">
						<small>{{ logos_dimensions.logo_isotipo.width }} x {{ logos_dimensions.logo_isotipo.height }} px</small>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-end mt-3">
			<button 
				type="submit" 
				class="btn btn-custom-color-blue rounded-pill mw-100"
			>
				Guardar
			</button>
		</div>

			<ModalCaptureImage
				:img="image"
				@crop="captureImage"
			/>

    <Spinner v-if="show_spinner" />

		<Question
			v-if="open_question_modal"
			:msg="question_modal_msg"
			:hideCancel="false"
			@cancel="closeQuestion"
			@accept="save"
		/>

	</form>
	
</template>